@import "variables";
@import "mixins";

.drink-list {
    @include wrap;
    @include vertical-wrap;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: $padding-xl;

    @include breakpoint($breakpoint-sm) {
        grid-template-columns: repeat(2, 1fr);
    }

    @include breakpoint($breakpoint-lg) {
        grid-template-columns: repeat(3, 1fr);
    }

    &-item {
        border: $border;
        border-radius: $border-radius;
        padding: $padding-md;

        img {
            width: 100%;
            margin-bottom: $padding-md;
        }
    }
}
