@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap');
@import "variables";

body {
    background-color: $background;
    color: $white;
    font-family: 'Inter', sans-serif;
}

#root {
    display: flex;
    flex-direction: column;
    max-width: 100vw;
}

* {
    box-sizing: border-box;
}

a {
    text-decoration: none;
    color: currentColor;
}

h1 {
    font-size: $h1;
    font-weight: 700;
}
