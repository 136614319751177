@import "variables";
@import "mixins";

.hero {
    position: relative;
    height: 40rem;
    display: flex;
    align-items: center;
    justify-content: center;

    &-inner {
        @include wrap;
        width: 100%;
        display: flex;
        flex-direction: column;
        z-index: 1;

        *>{
        }

        h1 {
            margin-bottom: $padding-md;
        }

        input {
        }
    }

    &-image {
        position: absolute;
        inset: 0;
        background-image: url("../../../public/background.png");
        background-position: top;
        background-size: cover;
        background-color: #000;
        //opacity: 0.75;
        z-index: 0;
    }


}
