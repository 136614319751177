// colors
$grey: #888888;
$grey-darken: darken($grey, 10%);
$grey-lighten: lighten($grey, 10%);
$brand: #4099FF;
$white: #FFFFFF;
$success: #2ECC71;
$error: #CC3333;
$foreground: #d5d5d5;
$background: #081426;
$accent: lighten($background, 5%);
$accent-active: lighten($background, 2%);

// borders
$accent-width: 0.25rem;
$border-width: 1px;
$border: $border-width solid $accent;
$border-radius: 0.5rem;

$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1092px;
$max-width: 75rem;

// transition timings
$tr-sm: 250ms ease-in-out;
$tr-md: 500ms ease-in-out;
$tr-lg: 750ms ease-in-out;

// custom sizes
$navbar-height: 3.5rem;
$scrollbar-height: 0.625rem;

// padding
$padding-sm: 0.375rem;
$padding-md: $padding-sm * 2;
$padding-lg: 1rem;
$padding-xl: $padding-lg * 2;
$padding-xxl: $padding-xl * 2;

// font sizes
$h1: 3rem;
$h2: 2rem;
$h3: 1.75rem;
$h4: 1.5rem;
$h5: 1.25rem;
$h6: 1.125rem;
$h7: 1rem;
$h8: 0.9rem;
