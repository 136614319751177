@import "mixins";

.search {
    padding-top: $padding-lg;
    padding-bottom: $padding-lg;
    background-color: $accent;

    &-inner {
        @include wrap;

        > * {
            width: 100%;
        }
    }
}

