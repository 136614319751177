@import "variables";

@mixin wrap {
    width: 100%;
    padding-left: $padding-xl;
    padding-right: $padding-xl;
    max-width: $max-width;
    margin: 0 auto;
    transition: padding $tr-sm;

    @media screen {
        @media (min-width: $breakpoint-md) {
            padding-left: $padding-xxl;
            padding-right: $padding-xxl;
        }
    }
}

@mixin vertical-wrap {
    width: 100%;
    padding-top: $padding-xl;
    padding-bottom: $padding-xl;
    transition: padding $tr-sm;

    @media screen {
        @media (min-width: $breakpoint-md) {
            padding-top: $padding-xxl;
            padding-bottom: $padding-xxl;
        }
    }
}

@mixin breakpoint($breakpoint){
    @media screen and (min-width: $breakpoint) {
        @content;
    }
}
