@import "mixins";

.metadata {
    @include vertical-wrap;
    background-color: $accent;
    user-select: none;

    &-inner {
        @include wrap;
    }

    &-info {
        margin-top: $padding-xl;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: $padding-xl;

        &-item {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            svg{
                margin-bottom: $padding-md;
            }
        }
    }
}

.details {
    @include wrap;
    @include vertical-wrap;
}
