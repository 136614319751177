.icon {
    fill: currentColor;

    &-small {
        width: 1.5rem;
        height: 1.5rem;
    }

    &-large {
        width: 2.75rem;
        height: 2.75rem;
    }
}
